<label class="form-label w-100">
    <div class="row">
      <div class="col d-flex align-items-center">{{label}}</div>
      <div class="col-auto">
        <div class="float-end d-flex">
<!--          <app-btn-lampada [nomeCampo]="id"></app-btn-lampada>-->
<!--          <app-btn-campo-oculto [nomeCampo]="id"></app-btn-campo-oculto>-->
        </div>
      </div>
    </div>
</label>

<mat-select
    [id]="id"
    [placeholder]="placeholder"
    class="form-multi-select"
    [ngClass]="{'is-invalid': invalid || campoPreenchidoBoolean}"
    [class.is-invalid-robo]="campoPreenchidoBoolean"
    [(ngModel)]="value"
    onkeyup="atividadeRobo()"
    (click)="atividadeRobo()">
    <mat-option *ngFor="let optionItem of listOptions; let i = index;" [value]="optionItem.value">
    {{optionItem.label}}
    </mat-option>
</mat-select>

<div *ngIf="invalid" class="invalid-feedback">
    <span>{{msgAlerta}}</span>
</div>
<div *ngIf="campoPreenchidoBoolean && campoPreenchidoBooleanValidado" class="invalid-feedback">
    <span>Campo pendente de validação</span>
</div>
