<label class="form-label w-100">
  <div class="row">
    <div class="col d-flex align-items-center">{{label}}</div>
    <div class="col-auto">
      <div class="float-end d-flex">
<!--        <app-btn-lampada [nomeCampo]="id"></app-btn-lampada>-->
<!--        <app-btn-campo-oculto [nomeCampo]="id"></app-btn-campo-oculto>-->
      </div>
    </div>
  </div>
</label>
<input
        type="number"
        [(ngModel)]="value"
        [disabled]="disabled"
        (input)="onChange(value)"
        (blur)="onTouched()"
        class="form-control"
        [id]="id"
        [placeholder]="placeholder"
        [ngClass]="{'is-invalid': invalid}"
        (keyup)="chamarExterno(value)"
        [mask]="mask"
        [suffix]="suffix"
/>
<div *ngIf="invalid" class="invalid-feedback">
  <span>{{msgAlerta}}</span>
</div>
