import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {TipoProspeccao, TipoProspeccaoEnum} from "../../../aplicacao/enum/tipoPessoaProspeccao.enum";
import {BibliotecaFormularioComponent} from "../../../bibliotecaPjus/componentes/biblioteca-formulario.component";
import {NovoParceiro, Telefone} from "../../../aplicacao/models/novo-parceiro.model";
import {FormBuilder} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {AlertaService} from "../../../bibliotecaPjus/services/alerta.service";
import {NovoParceiroService} from "../../../services/novo-parceiro.service";
import {NgxSpinnerService} from "ngx-spinner";
import {MatDialog} from "@angular/material/dialog";
import {StepperPreCadastroParceiro} from "../../../aplicacao/enum/StepperPreCadastroParceiro.enum";

@Component({
  selector: 'app-formulario-pre-cadastro',
  templateUrl: './formulario-pre-cadastro.component.html',
  styleUrls: ['./formulario-pre-cadastro.component.scss']
})
export class FormularioPreCadastroComponent extends BibliotecaFormularioComponent<NovoParceiro> implements OnInit, OnDestroy {
  selectedFiles;
  nomeArquivo;
  @Output() pularStep = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    protected router: Router,
    protected route: ActivatedRoute,
    protected alertaService: AlertaService,
    protected novoParceiroService: NovoParceiroService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog) {
    super(
      router,
      route,
      alertaService,
      novoParceiroService,
      ['Novo Parceiro', 'Editar Parceiro'],
      [null, null]
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.start();
    if(this.novoParceiroService?.model?.id){
      this.removerId = false;
      this.model = this.novoParceiroService.model;
      this.form = this.model.asForm;
      this.form.controls.id.setValue(this.model.id);
    }
  }

  depoisCarregar(model: NovoParceiro) {
    super.depoisCarregar(model);
    if (!model.id) {
      this.adicionarTelefone();
    }
  }

  campoInvalido(ac, nomeCampo): boolean {
    return ac.controls[nomeCampo].invalid;
  }

  campoInvalidoTelefone(ac, nomeCampo): string {
    if(ac.controls[nomeCampo].invalid && !this.validarFormulario) {
      if(ac.controls[nomeCampo].errors.minlength) {
        return 'Telefone inválido';
      } else {
        return 'Campo obrigatório';
      }
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  antesEnviar(): void {
    this.validarFormulario = false;
    this.carregarManual = true;
    console.log(this.form);
    if (this.form.valid){
      this.spinner.show();
      this.validarFormulario = true;
    }
  }

  propostaId = null;
  depoisEnviar(model: NovoParceiro) {
    super.depoisEnviar(model);
    this.novoParceiroService.model = model;
    this.spinner.hide();
    this.retornarLink = 'cadastro';
    this.pularStep.emit(StepperPreCadastroParceiro.Avancar)
  }

  erroSalvar(e) {
    this.spinner.hide();
  }

  adicionarTelefone(): void {
    if(this.model.asTelefoneContatoFormArray.length < 5){
      if(this.model.asTelefoneContatoFormArray.valid) {
        this.model.asTelefoneContatoFormArray.push(new Telefone().asForm);
      } else {
        this.alertaService.erro('Preencha o campo de telefone.');
      }

    } else {
      this.alertaService.erro('Você atingiu o limite de telefones cadastrados.');
    }
  }

  removerTelefone(index: number): void {
    this.model.asTelefoneContatoFormArray.removeAt(index);
  }

  toggleAceite(): void {
    const currentValue = this.form.get('aceite').value;
    this.form.get('aceite').setValue(!currentValue);
  }

  protected readonly TipoProspeccao = TipoProspeccao;
  protected readonly TipoProspeccaoEnum = TipoProspeccaoEnum;
}
