import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {HttpService} from "../core/services/http.service";
import {HttpClient} from "@angular/common/http";
@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(private httpService: HttpService,
              private http: HttpClient
              ) { }

  getEstadosBrasileiros(): Observable<any[]> {
    return of (
      [
        {value: "Acre", key: "AC"},
        {value: "Alagoas", key: "AL"},
        {value: "Amapá", key: "AP"},
        {value: "Amazonas", key: "AM"},
        {value: "Bahia", key: "BA"},
        {value: "Ceará", key: "CE"},
        {value: "Distrito Federal", key: "DF"},
        {value: "Espírito Santo", key: "ES"},
        {value: "Goiás", key: "GO"},
        {value: "Maranhão", key: "MA"},
        {value: "Mato Grosso", key: "MT"},
        {value: "Mato Grosso do Sul", key: "MS"},
        {value: "Minas Gerais", key: "MG"},
        {value: "Pará", key: "PA"},
        {value: "Paraíba", key: "PB"},
        {value: "Paraná", key: "PR"},
        {value: "Pernambuco", key: "PE"},
        {value: "Piauí", key: "PI"},
        {value: "Rio de Janeiro", key: "RJ"},
        {value: "Rio Grande do Norte", key: "RN"},
        {value: "Rio Grande do Sul", key: "RS"},
        {value: "Rondônia", key: "RO"},
        {value: "Roraima", key: "RR"},
        {value: "Santa Catarina", key: "SC"},
        {value: "São Paulo", key: "SP"},
        {value: "Sergipe", key: "SE"},
        {value: "Tocantins", key: "TO"}
      ]
    )
  }

  buscarCep(cep: string): Observable<any> {
    const url = `https://viacep.com.br/ws/${cep}/json/`;
    return this.http.get<any>(url);
  }

  getEstadoSelecionado(sigla:string){
    let nomeEstado = '';
    this.getEstadosBrasileiros().subscribe(
      (r:any) => {
        for(let item of r){
          if(item.sigla == sigla){
            nomeEstado = item.nome;
          }
        }
      }
    )
    return nomeEstado;
  }

  //Verifica se uma data (dd/MM/yyyy) é válida
  verificarDataValida(dataString:string) {
    const dataSplit = dataString.split('/');
    const dia = parseInt(dataSplit[0]);
    const mes = parseInt(dataSplit[1]);
    const ano = parseInt(dataSplit[2]);
    let diaValido = dia > 0;
    let mesValido = mes > 0;
    let anodValido = ano > 1753;
    return diaValido && mesValido && anodValido;
  }

  // Recebe uma data (dd/MM/yyyy) e formata para (yyyy-MM-dd)
  formatarDataParaSalvarNoBanco(dataString:string) {
    const dataSlipt = dataString.split('/');
    const dia = dataSlipt[0];
    const mes = dataSlipt[1];
    const ano = dataSlipt[2];
    const formattedDate = `${ano}-${mes.padStart(2, '0')}-${dia.padStart(2, '0')}`;
    return formattedDate;
  }

  formatarValor(valor: number): string {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(valor);
  }

  getMinucipiosPorEstado(uf:string): Observable<any> {
    return this.httpService.get(this.httpService.baseUrl + this.httpService.apiVersion + `/UnidadesFederativas/Estados/Cidades?uf=${uf}`);
  }

  getTribunais() {
    return [
      "Supremo Tribunal Federal (STF)",
      "Superior Tribunal de Justiça (STJ)",
      "Superior Tribunal Militar (STM)",
      "Tribunal Superior do Trabalho (TST)",
      "Tribunal Regional Federal da 1ª Região (TRF1) (AC, AM, AP, BA, DF, GO, MA, MT, PA, PI, RO, RR e TO)",
      "Tribunal Regional Federal da 2ª Região (TRF2) (ES e RJ)",
      "Tribunal Regional Federal da 3ª Região (TRF3) (MS e SP)",
      "Tribunal Regional Federal da 4ª Região (TRF4) (PR, RS e SC)",
      "Tribunal Regional Federal da 5ª Região (TRF5) (AL, CE, PB, PE, RN e SE)",
      "Tribunal Regional Federal da 6ª Região (TRF6) (MG)",
      "Tribunal de Justiça do Acre (TJAC)",
      "Tribunal de Justiça de Alagoas (TJAL)",
      "Tribunal de Justiça do Amapá (TJAP)",
      "Tribunal de Justiça do Amazonas (TJAM)",
      "Tribunal de Justiça da Bahia (TJBA)",
      "Tribunal de Justiça do Ceará (TJCE)",
      "Tribunal de Justiça do Distrito Federal e Territórios (TJDFT)",
      "Tribunal de Justiça do Espírito Santo (TJES)",
      "Tribunal de Justiça de Goiás (TJGO)",
      "Tribunal de Justiça do Maranhão (TJMA)",
      "Tribunal de Justiça de Mato Grosso (TJMT)",
      "Tribunal de Justiça de Mato Grosso do Sul (TJMS)",
      "Tribunal de Justiça de Minas Gerais (TJMG)",
      "Tribunal de Justiça do Pará (TJPA)",
      "Tribunal de Justiça da Paraíba (TJPB)",
      "Tribunal de Justiça do Paraná (TJPR)",
      "Tribunal de Justiça de Pernambuco (TJPE)",
      "Tribunal de Justiça do Piauí (TJPI)",
      "Tribunal de Justiça do Rio de Janeiro (TJRJ)",
      "Tribunal de Justiça do Rio Grande do Norte (TJRN)",
      "Tribunal de Justiça do Rio Grande do Sul (TJRS)",
      "Tribunal de Justiça de Rondônia (TJRO)",
      "Tribunal de Justiça de Roraima (TJRR)",
      "Tribunal de Justiça de Santa Catarina (TJSC)",
      "Tribunal de Justiça de São Paulo (TJSP)",
      "Tribunal de Justiça de Sergipe (TJSE)",
      "Tribunal de Justiça do Tocantins (TJTO)",
      "Tribunal Regional do Trabalho da 1ª Região (TRT1) (RJ)",
      "Tribunal Regional do Trabalho da 2ª Região (TRT2) (SP / Grande São Paulo e Baixada Santista)",
      "Tribunal Regional do Trabalho da 3ª Região (TRT3) (MG)",
      "Tribunal Regional do Trabalho da 4ª Região (TRT4) (RS)",
      "Tribunal Regional do Trabalho da 5ª Região (TRT5) (BA)",
      "Tribunal Regional do Trabalho da 6ª Região (TRT6) (PE)",
      "Tribunal Regional do Trabalho da 7ª Região (TRT7) (CE)",
      "Tribunal Regional do Trabalho da 8ª Região (TRT8) (AP e PA)",
      "Tribunal Regional do Trabalho da 9ª Região (TRT9) (PR)",
      "Tribunal Regional do Trabalho da 10ª Região (TRT10) (DF e TO)",
      "Tribunal Regional do Trabalho da 11ª Região (TRT11) (AM e RR)",
      "Tribunal Regional do Trabalho da 12ª Região (TRT12) (SC)",
      "Tribunal Regional do Trabalho da 13ª Região (TRT13) (PB)",
      "Tribunal Regional do Trabalho da 14ª Região (TRT14) (AC e RO)",
      "Tribunal Regional do Trabalho da 15ª Região (TRT15) (SP / Interior e Litoral Norte e Sul)",
      "Tribunal Regional do Trabalho da 16ª Região (TRT16) (MA)",
      "Tribunal Regional do Trabalho da 17ª Região (TRT17) (ES)",
      "Tribunal Regional do Trabalho da 18ª Região (TRT18) (GO)",
      "Tribunal Regional do Trabalho da 19ª Região (TRT19) (AL)",
      "Tribunal Regional do Trabalho da 20ª Região (TRT20) (SE)",
      "Tribunal Regional do Trabalho da 21ª Região (TRT21) (RN)",
      "Tribunal Regional do Trabalho da 22ª Região (TRT22) (PI)",
      "Tribunal Regional do Trabalho da 23ª Região (TRT23) (MT)",
      "Tribunal Regional do Trabalho da 24ª Região (TRT24) (MS)",
      "Tribunal de Justiça Militar de Minas Gerais (TJMMG)",
      "Tribunal de Justiça Militar do Rio Grande do Sul (TJMRS)",
      "Tribunal de Justiça Militar de São Paulo (TJMSP)"
    ]
  }
}
