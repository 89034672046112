import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AbstractControl, ValidationErrors} from "@angular/forms";

@Component({
  selector: 'app-input-upload-file-simple',
  templateUrl: './input-upload-file-simple.component.html',
  styleUrls: ['./input-upload-file-simple.component.scss']
})
export class InputUploadFileSimpleComponent {
  nomeArquivo: string = '';
  selectedFiles;
  @Input() id = 'inputId';
  @Input() placeholder = '';
  @Input() invalid = false;
  @Input() base64Valid = false;
  @Input() label: string = '';
  @Input() textoBotao: string = 'Enviar Documento';
  @Input() cssBotao: string = '';
  @Input() msgAlerta = 'Campo obrigatório';
  @Input() mask;
  @Input() suffix = '';
  @Input() required = true;
  @Output() base64 = new EventEmitter();
  uploadArquivo(event): void {
    this.selectedFiles = event;
    if (this.selectedFiles.target.files && this.selectedFiles.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const r = e.target.result;
        if (typeof e.target.result === 'string') {
          const base64String = e.target.result.split(',').pop();
          const extensao = this.selectedFiles.target.files[0].name.split('.').pop().toLowerCase()
          this.base64.emit({base64String, extensao});
          this.nomeArquivo = this.selectedFiles.target.files[0].name;
        }
      };
      reader.readAsDataURL(this.selectedFiles.target.files[0]);
    }
  }

  value: string;
  disabled = false;
  onChange: any = (value: any) => {};
  onTouched: any = () => {};

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  validate(control: AbstractControl): ValidationErrors {
    if(this.required) {
      if (!control.value || control.value === '') {
        return { required: true };
      }
    } else {
      return null;
    }
  }

}
