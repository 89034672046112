import {Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validators} from "@angular/forms";

@Component({
  selector: 'app-input-senha',
  templateUrl: './input-senha.component.html',
  styleUrls: ['./input-senha.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputSenhaComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputSenhaComponent),
      multi: true
    }
  ],
})
export class InputSenhaComponent {
  @Input() inputId = '';
  @Input() placeholder = '';
  @Input() invalid = false;
  @Input() readonly = false;
  @Input() label = '';
  @Input() mask = '';
  @Input() msgAlerta = 'Campo obrigatório';
  @Input() required = true;
  @Output() onFocus = new EventEmitter();
  @Output() onKeyUp = new EventEmitter();
  @Input() value: string;
  disabled = false;
  onChange: any = (value: any) => {};
  onTouched: any = () => {};

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if(control.hasValidator(Validators.required)) {
      if (!control.value) {
        return { required: true };
      }
    } else {
      return null;
    }
  }

  inputKeyup(ev): void {
    this.onKeyUp.emit(ev);
  }

  inputFocus(): void {
    this.onFocus.emit(this.inputId);
  }
}
