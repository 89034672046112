import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {BibliotecaFormularioComponent} from "../../../bibliotecaPjus/componentes/biblioteca-formulario.component";
import {FormBuilder, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {AlertaService} from "../../../bibliotecaPjus/services/alerta.service";
import {NovoParceiroService} from "../../../services/novo-parceiro.service";
import {NgxSpinnerService} from "ngx-spinner";
import {MatDialog} from "@angular/material/dialog";
import {StepperPreCadastroParceiro} from "../../../aplicacao/enum/StepperPreCadastroParceiro.enum";
import {TipoProspeccao} from "../../../aplicacao/enum/tipoPessoaProspeccao.enum";
import {NovoParceiroDadosCompletaresModel} from "../../../aplicacao/models/novo-parceiro-dados-completares.model";
import {NovoParceiroDadosCompletaresService} from "../../../services/novo-parceiro-dados-completares.service";
import {UtilsService} from "../../../services/utils.service";

@Component({
  selector: 'app-formulario-dados-complementares',
  templateUrl: './formulario-dados-complementares.component.html',
  styleUrls: ['./formulario-dados-complementares.component.scss']
})
export class FormularioDadosComplementaresComponent extends BibliotecaFormularioComponent<NovoParceiroDadosCompletaresModel> implements OnInit, OnDestroy {
  selectedFiles;
  nomeArquivo;
  estados;
  @Output() pularStep = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    protected router: Router,
    protected route: ActivatedRoute,
    protected alertaService: AlertaService,
    protected novoParceiroDadosCompletaresService: NovoParceiroDadosCompletaresService,
    protected novoParceiroService: NovoParceiroService,
    public utilService: UtilsService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog) {
    super(
      router,
      route,
      alertaService,
      novoParceiroDadosCompletaresService,
      ['Novo Parceiro', 'Editar Parceiro'],
      [null, null]
    );
  }
  ngOnInit(): void {
    super.ngOnInit();
    this.start();
    this.utilService.getEstadosBrasileiros().subscribe( res => {
      this.estados = res;
    });
    if(this.novoParceiroService?.model?.id) {
      this.removerId = false;
      this.form.controls.id.setValue(this.novoParceiroService.model.id);
    }
  }

  depoisCarregar(model: NovoParceiroDadosCompletaresModel) {
    super.depoisCarregar(model);
    model.tipoPessoa = this.novoParceiroService.model.tipoPessoa;
  }

  campoInvalido(ac, nomeCampo): boolean {
    return ac.controls[nomeCampo].invalid;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  antesEnviar(): void {
    this.validarFormulario = false;
    this.carregarManual = true;
    if(this.novoParceiroService.model) {
      if(this.novoParceiroService.model.tipoPessoa === TipoProspeccao.Fisica) {
        this.removerValidator('nomeContato');
      } else {
        this.removerValidator('documentoRg');
        this.removerValidator('orgaoEmissor');
        this.removerValidator('dataNascimento');
        this.removerValidator('nome');
      }
    }
    this.removerValidator('complemento');
    if (this.form.valid){
      this.spinner.show();
      this.validarFormulario = true;
    }
  }


tornarCampoObrigatorio(): boolean {
  if (this.fc['documentoRg'].value) {
    this.form.controls['orgaoEmissor'].setValidators([Validators.required]);
    this.form.controls['orgaoEmissor'].updateValueAndValidity();
    return true;
  } else {
    this.removerValidator('orgaoEmissor');
    return false;
  }
}


  propostaId = null;
  depoisEnviar(model: NovoParceiroDadosCompletaresModel) {
    super.depoisEnviar(model);
    this.spinner.hide();
    this.retornarLink = 'cadastro';
    this.pularStep.emit(StepperPreCadastroParceiro.Avancar)
  }

  erroSalvar(e) {
    this.spinner.hide();
  }

  enderecoEncontrado = true;
  buscarCep(ev): void {
    if(ev.length === 8) {
      this.spinner.show();
      this.utilService.buscarCep(ev).subscribe(res => {
        this.spinner.hide();
        if(res.erro) {
          this.alertaService.erro('CEP não encontrado');
          this.enderecoEncontrado = false;
          this.form.controls['logradouro'].setValue(null);
          this.form.controls['bairro'].setValue(null);
          this.form.controls['cidade'].setValue(null);
          this.form.controls['uf'].setValue(null);
          this.form.controls['numero'].setValue(null);
          this.form.controls['complemento'].setValue(null);
        } else {
          this.form.controls['logradouro'].setValue(res.logradouro);
          this.form.controls['bairro'].setValue(res.bairro);
          this.form.controls['cidade'].setValue(res.localidade);
          this.form.controls['uf'].setValue(res.uf);
          this.form.controls['numero'].setValue(null);
          this.form.controls['complemento'].setValue(null);
          this.enderecoEncontrado = true;
        }
      }, error => {
        this.spinner.hide();
      });
    }
  }

  validandoDataNascimento(): string {
    const errors = this.form.controls['dataNascimento'].errors;
    if (errors) {
      return errors['menorIdade'] ? 'Usuário menor de idade' : 'Campo obrigatório';
    }
    return '';
  }

  protected readonly TipoProspeccao = TipoProspeccao;
  protected readonly StepperPreCadastroParceiro = StepperPreCadastroParceiro;
}
