import {TipoFormulario} from "../enums/enumGenerico.enum";

/**
 * String baseada no tipo de edição do formulário que permite diferenciar string para Criação ou Edição
 */
export class BibliotecaFormularioString {
  public criar: string;
  public editar: string;

  /**
   * Construtor da string de formulário
   * @param outro Array com duas strings para criação e edição, objeto com atributos de criação e edição ou string de criação
   * @param editar Sem definição ou string de edição
   */
  constructor (outro: any, editar?: string) {
    this.criar = '';
    this.editar = '';
    if (outro != null) {
      if (outro instanceof Array) {
        this.criar = (outro.length > 0) ? outro[0] : '';
        this.editar = (outro.length > 1) ? outro[1] : '';
      } else if (typeof outro == 'object') {
        this.criar = outro.create || '';
        this.editar = outro.editar || '';
      } else {
        this.criar = outro || '';
        this.editar = editar || '';
      }
    }
  }

  get(tipo: TipoFormulario): string {
    return (tipo == TipoFormulario.Criar) ? this.criar : this.editar;
  }
}
