import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";
import {FiltroPaginacao, ListaPaginada} from "../bibliotecaPjus/models/comum.models";
import {IBibliotecaFormularioService, IBibliotecaTabelaService} from "../bibliotecaPjus/services/contrato.services";
import {BaseProviderService} from "./base-provider.service";
import {FiltroPropostaModel} from "../aplicacao/models/filtro-proposta.model";
import {NovoParceiroDadosCompletaresModel} from "../aplicacao/models/novo-parceiro-dados-completares.model";

@Injectable({
  providedIn: 'root'
})
export class NovoParceiroDadosCompletaresService extends BaseProviderService implements IBibliotecaTabelaService<NovoParceiroDadosCompletaresModel, any, any>, IBibliotecaFormularioService<NovoParceiroDadosCompletaresModel> {
  protected module = 'Prospeccao';

  constructor(protected http: HttpClient) {
    super(http);
  }

  criar(): NovoParceiroDadosCompletaresModel {
    return new NovoParceiroDadosCompletaresModel();
  }

  get(id: string): Observable<NovoParceiroDadosCompletaresModel> {
    return this.http.get<NovoParceiroDadosCompletaresModel>(this.getUrl(this.v1, this.module, [id]))
      .pipe(map(x => new NovoParceiroDadosCompletaresModel(x)));
  }

  list(pagina: number, qtd: number, status, objFiltro, subStatus = null): Observable<any> {
    const filtro = new FiltroPropostaModel(objFiltro);
    return this.http.get(this.getUrl(this.v1, this.module, ['Recuperar', 'Parceiro', 'Pagina', pagina, 'Quantidade', qtd + '?StatusProposta='+ status +
    (filtro.valorMin ? '&ValorMinimo=' + filtro.valorMin : '') +
    (filtro.valorMax ? '&ValorMaximo=' + filtro.valorMax : '') +
    (filtro.periodo ? '&DataInicio=' + filtro.periodo.start : '') +
    (filtro.periodo ? '&DataFim=' + filtro.periodo.end : '') +
    (filtro.documento ? '&DocumentoCedente=' + filtro.documento : '') +
    (subStatus ? '&' + subStatus : '')
    ])).pipe(map(d => new ListaPaginada<NovoParceiroDadosCompletaresModel>(NovoParceiroDadosCompletaresModel, d)));
  }

  filter(filter: FiltroPaginacao<any, any>): Observable<ListaPaginada<NovoParceiroDadosCompletaresModel>> {
    return this.http.post<ListaPaginada<NovoParceiroDadosCompletaresModel>>(this.getUrl(this.v1, this.module, ['filter']), filter)
      .pipe(map(d => new ListaPaginada<NovoParceiroDadosCompletaresModel>(NovoParceiroDadosCompletaresModel, d)));
  }

  atualizar(model: NovoParceiroDadosCompletaresModel): Observable<NovoParceiroDadosCompletaresModel> {
    return this.http.patch<NovoParceiroDadosCompletaresModel>(this.getUrl(this.v1, this.module, [model.id]), model).pipe(map(x => new NovoParceiroDadosCompletaresModel(x)));
  }

  salvar(model: NovoParceiroDadosCompletaresModel): Observable<NovoParceiroDadosCompletaresModel> {
    return this.http.post<NovoParceiroDadosCompletaresModel>(this.getUrl(this.v1, this.module, ['DadosComplementares', 'Salvar']), model).pipe(map(x => new NovoParceiroDadosCompletaresModel(x)));
  }

  aceitarOferta(model): Observable<NovoParceiroDadosCompletaresModel> {
    return this.http.post<NovoParceiroDadosCompletaresModel>(this.getUrl(this.v1, this.module, ['Aceite', 'Parceiro']), model).pipe(map(x => x));
  }

  deletar(id: number): Observable<NovoParceiroDadosCompletaresModel> {
    return this.http.delete<NovoParceiroDadosCompletaresModel>(this.getUrl(this.v1, this.module, [id]))
      .pipe(map(x => new NovoParceiroDadosCompletaresModel(x)));
  }

  cancelarProposta(model): Observable<NovoParceiroDadosCompletaresModel> {
    return this.http.post<NovoParceiroDadosCompletaresModel>(this.getUrl(this.v1, this.module, ['Parceiro', 'ReprovarProposta']), model).pipe(map(x => x));
  }

  novoCalculo(model): Observable<NovoParceiroDadosCompletaresModel> {
    return this.http.post<NovoParceiroDadosCompletaresModel>(this.getUrl(this.v1, this.module, ['NovoCalculo', 'Proposta', model.propostaId]), model).pipe(map(x => x));
  }

  contraProposta(model: any): Observable<any> {
    return this.http.post(this.getUrl(this.v1, this.module, ['Parceiro', 'GerarContraProposta']), model).pipe(map(x => x));
  }

}
