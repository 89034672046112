<label class="align-items-center d-flex desktop_body_small form-label w-100" *ngIf="tooltipAtivo"
       [matTooltip]="tooltipTxt"
       [matTooltipPosition]="tooltipPosicao">
  <div class="row w-100">
    <div class="col d-flex align-items-center">
      {{label}}
      <img src="assets/imgs/icones/info-azul.svg" class="ms-1" style="width: 12px; height: 12px" alt="">
    </div>
    <div class="col p-0">
      <div class="float-end d-flex">
<!--        <app-btn-lampada [nomeCampo]="id"></app-btn-lampada>-->
<!--        <app-btn-campo-oculto [nomeCampo]="id"></app-btn-campo-oculto>-->
      </div>
    </div>
  </div>
</label>
<label class="desktop_body_small form-label" *ngIf="!tooltipAtivo">{{label}}</label>
<div class="row">
    <div class="col-12">
        <div class="switch-toggle-3">
            <input (click)="selecyType(true)" value="2" [id]="'sim-destacado-' + id" [checked]="value === true" [name]="'acao-destacado-'+id" type="radio">
            <label class="" [for]="'sim-destacado-' + id" onclick="">Sim</label>
            <input (click)="selecyType(false)" value="3" [id]="'nao-destacado-' + id" [checked]="value === false" [name]="'acao-destacado-'+id" type="radio">
            <label class="" [for]="'nao-destacado-' + id" onclick="">Não</label>
            <a></a>
        </div>
    </div>
</div>
<div *ngIf="invalid" class="invalid-feedback-inputs">
    <span>{{msgAlerta}}</span>
</div>
