import {Component, Input} from '@angular/core';
import {AbstractControl, ControlValueAccessor, ValidationErrors, Validator} from "@angular/forms";

@Component({
  selector: 'app-input-mask',
  templateUrl: './input-mask.component.html',
  styleUrls: ['./input-mask.component.scss']
})
export class InputMaskComponent implements ControlValueAccessor, Validator  {
  @Input() id = 'inputId'
  @Input() placeholder = 'Definir placeholder'
  @Input() invalid = false;
  @Input() label = 'Definir Label';
  @Input() msgAlerta = 'Campo obrigatório';
  @Input() mask = null;
  value: string;
  disabled = false;
  onChange: any = (value: any) => { };
  onTouched: any = () => {};

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (!control.value || control.value === '') {
      return { required: true };
    }
    return null;
  }

  ev(ev): void {
    this.onChange(ev);
  }
}
