<label class="form-label desktop_body_small">
  {{label}}
  <div class="float-end d-flex">
<!--    <app-btn-lampada [nomeCampo]="id"></app-btn-lampada>-->
<!--    <app-btn-campo-oculto [nomeCampo]="id" *ngIf="visivel"></app-btn-campo-oculto>-->
  </div>
</label>
<div class="row">
  <div class="col-12">
    <div class="switch-toggle-3">
      <input (click)="selecyType(inputUm?.value)" [value]="inputUm?.value" [id]="'valor-um-' + id" [checked]="parseInt(value, 0) === inputUm?.value" [name]="'acao-destacado-'+id" type="radio">
      <label class="" [for]="'valor-um-' + id" onclick="">{{inputUm?.label}}</label>
      <input (click)="selecyType(inputDois?.value)" [value]="inputDois?.value" [id]="'valor-dois-' + id" [checked]="parseInt(value, 0) === inputDois?.value" [name]="'acao-destacado-'+id" type="radio">
      <label class="" [for]="'valor-dois-' + id" onclick="">{{inputDois?.label}}</label>
      <a></a>
    </div>
  </div>
</div>
<div *ngIf="invalid" class="invalid-feedback-inputs">
  <span>{{msgAlerta}}</span>
</div>
