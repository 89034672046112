import {Auditoria} from "../../bibliotecaPjus/models/comum.models";
import {IFormulario, IModel} from "../../bibliotecaPjus/models/contrato.models";
import {FormControl, FormGroup, Validators} from "@angular/forms";

export class FiltroPropostaModel extends Auditoria implements IModel, IFormulario {
  pagina: number;
  quantidade: number;
  statusProposta: string;
  subStatus: string;
  responsavel: string;
  valorMin: number;
  valorMax: number;
  periodo: {
    start;
    end;
  };
  periodoMobile: {
    start;
    end;
  };
  dataInicio;
  dataFim;
  documento: string;
  form: FormGroup;
  constructor(other?: any) {
    super(other);
    if (other) {
      this.pagina = other.pagina;
      this.quantidade = other.quantidade;
      this.statusProposta = other.statusProposta;
      this.subStatus = other.subStatus;
      this.valorMin = other.valorMin;
      this.valorMax = other.valorMax;
      this.periodo = other.periodo;
      this.documento = other.documento;
      this.responsavel = other.responsavel;
      this.dataInicio = other.dataInicio;
      this.dataFim = other.dataFim;
    }
  }

  get asForm(): FormGroup {
    if (this.form == null) {
      this.form = new FormGroup({
        subStatus: new FormControl(this.subStatus),
        statusProposta: new FormControl(this.statusProposta),
        valorMax: new FormControl(this.valorMax),
        valorMin: new FormControl(this.valorMin),
        periodo: new FormControl(this.periodo),
        documento: new FormControl(this.documento),
        responsavel: new FormControl(this.responsavel),
        dataInicio: new FormControl(this.dataInicio),
        dataFim: new FormControl(this.dataFim),
      });
    }
    return this.form;
  }

  updateForm(other): void {
    this.form.patchValue(other);
  }
}
