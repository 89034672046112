import {Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from "@angular/forms";

@Component({
  selector: 'app-input-radio-boolean',
  templateUrl: './input-radio-boolean.component.html',
  styleUrls: ['./input-radio-boolean.component.scss'],providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputRadioBooleanComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputRadioBooleanComponent),
      multi: true
    }
  ]
})
export class InputRadioBooleanComponent  implements ControlValueAccessor, Validator  {
  @Input() id = 'inputId'
  @Input() placeholder = 'Definir placeholder'
  @Input() invalid = false;
  @Input() label = 'Definir Label';
  @Input() tooltipAtivo = false;
  @Input() tooltipTxt;
  @Input() tooltipPosicao;
  @Input() tipoNumber = true;
  @Input() msgAlerta = 'Campo obrigatório';
  @Output() funcaoExterna = new EventEmitter();
  value;
  disabled = false;
  onChange: any = (value: any) => {};
  onTouched: any = () => {};

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (control.value === null || control.value === '') {
      return { required: true };
    }
    return null;
  }

  selecyType(ev): void {
    this.onChange(ev);
    this.funcaoExterna.emit(ev)
  }

  protected readonly parseInt = parseInt;
}
