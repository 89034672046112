import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {AlertaService} from "../../../bibliotecaPjus/services/alerta.service";
import {MatDialog} from "@angular/material/dialog";
import {TipoProspeccao} from "../../../aplicacao/enum/tipoPessoaProspeccao.enum";
import {StepperPreCadastroParceiro} from "../../../aplicacao/enum/StepperPreCadastroParceiro.enum";
import {NovoParceiroService} from "../../../services/novo-parceiro.service";

@Component({
  selector: 'app-formulario-inicial-cadastro-parceiro',
  templateUrl: './formulario-inicial-cadastro-parceiro.component.html',
  styleUrls: ['./formulario-inicial-cadastro-parceiro.component.scss']
})
export class FormularioInicialCadastroParceiroComponent implements OnInit, OnDestroy {
  nomeArquivo;
  totalConcluido = '33.33%';
  tituloCadastro: string = 'Boas vindas à PJUS!';
  descricaoCadastro: string = 'Queremos oferecer a melhor experiência para você. Por favor, selecione uma opção*:';
  constructor(
    private fb: FormBuilder,
    protected router: Router,
    protected route: ActivatedRoute,
    protected alertaService: AlertaService,
    protected novoParceiroService: NovoParceiroService,
    public dialog: MatDialog) { }
  ngOnInit(): void { }


  ngOnDestroy(): void { }

  stepperFormulario = 0;
  stepper(tipo): void {
    if(tipo === StepperPreCadastroParceiro.Avancar){
      this.stepperFormulario++;
    } else {
      this.stepperFormulario--;
    }
    this.stepperConcluido();
  }

  stepperConcluido(): void {
    switch (this.stepperFormulario) {
      case 0:
        this.tituloCadastro = 'Boas vindas à PJUS!';
        this.descricaoCadastro = 'Queremos oferecer a melhor experiência para você. Por favor, selecione uma opção*:';
        this.totalConcluido = '33.33%';
        break;
      case 1:
        if(this.novoParceiroService.model.tipoPessoa === TipoProspeccao.Juridica) {
          this.tituloCadastro = 'Sua empresa está perto de ser parceira da PJUS!';
          this.descricaoCadastro = 'Para agilizar a viabilidade da nossa parceria, precisamos de algumas informações da ' +
            'sua empresa. Não se preocupe, seus dados estão protegidos aqui na PJUS.';
        } else {
          this.tituloCadastro = 'Você está perto de ser parceiro da PJUS!';
          this.descricaoCadastro = 'Para agilizar a viabilidade da nossa parceria, precisamos de ' +
            'algumas informações. <strong>Não se preocupe, seus dados estão protegidos aqui na PJUS.</strong>';
        }
        this.totalConcluido = '66.66%';
        break;
      case 2:
        this.tituloCadastro = 'Vamos analisar os dados inseridos';
        this.descricaoCadastro = 'Assim que finalizarmos a análise, enviaremos as orientações para os próximos passos';
        this.totalConcluido = '100%';
        break;
      default:
        this.tituloCadastro = 'Boas vindas à PJUS!';
        this.descricaoCadastro = 'Queremos oferecer a melhor experiência para você. Por favor, selecione uma opção*:';
        this.totalConcluido = '33.33%';
    }
  }

  protected readonly TipoProspeccao = TipoProspeccao;
}
