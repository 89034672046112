<label class="form-label texto-secundario formulario-label texto-inter" [class.required]="required">{{ label }}</label>
<input
  [id]="inputId"
  type="password"
  class="form-control"
  [placeholder]="placeholder"
  [(ngModel)]="value"
  [disabled]="disabled"
  (change)="onChange(value)"
  (focus)="inputFocus()"
  (keyup)="inputKeyup(value)"
  [ngClass]="{'is-invalid': invalid}"
  [mask]="mask"
  [dropSpecialCharacters]="false"
  [readonly]="readonly"
/>
<div class="invalid-feedback">
  <span>{{msgAlerta}}</span>
</div>
