import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {PaginaNaoEncontradaComponent} from "./modules/erros/pagina-nao-encontrada/pagina-nao-encontrada.component";
import {
  FormularioInicialCadastroParceiroComponent
} from "./modules/novo-parceiro/formulario-inicial-cadastro-parceiro/formulario-inicial-cadastro-parceiro.component";

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./modules/pages/pages.module').then((m) => m.PagesModule)
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./core/auth/auth.module').then((m) => m.AuthModule)
  },
  {
    path: 'autenticacao',
    loadChildren: () =>
      import('./core/autenticacao/autenticacao.module').then((m) => m.AutenticacaoModule)
  },
  {
    path: 'novo-parceiro',
    loadChildren: () =>
      import('./modules/novo-parceiro/novo-parceiro.module').then((m) => m.NovoParceiroModule)
  },
  {
    path: 'cadastro-parceiro',
    component: FormularioInicialCadastroParceiroComponent
  },
  {
    path: '**',
    component: PaginaNaoEncontradaComponent,
    loadChildren: () => import('./core/auth/auth.module').then((m) => m.AuthModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
