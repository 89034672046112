import {Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from "@angular/forms";

@Component({
  selector: 'app-input-numeros-inteiros',
  templateUrl: './input-numeros-inteiros.component.html',
  styleUrls: ['./input-numeros-inteiros.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputNumerosInteirosComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputNumerosInteirosComponent),
      multi: true
    }
  ]
})
export class InputNumerosInteirosComponent implements ControlValueAccessor, Validator  {
  @Input() id = 'inputId'
  @Input() placeholder = 'Definir placeholder'
  @Input() invalid = false;
  @Input() label = 'Definir Label';
  @Input() msgAlerta = 'Campo obrigatório';
  @Input() mask;
  @Input() suffix = '';
  @Input() required = true;
  @Output() funcaoExterna = new EventEmitter();
  value: string;
  disabled = false;
  onChange: any = (value: any) => {};
  onTouched: any = () => {};

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if(this.required) {
      if (!control.value || control.value === '') {
        return { required: true };
      }
    } else {
      return null;
    }
  }

  chamarExterno(value: any): void {
    this.funcaoExterna.emit(value)
  }
}
