<button mat-icon-button type="button" color="primary" *ngIf="image" class="btn-delete-float" (click)="removerImagem()"><img src="assets/imgs/icones/trash.svg" class="w-100" alt=""></button>
<div class="border-dashed card-view-doc" >
  <div class="d-flex justify-content-center align-items-center h-100" *ngIf="!image">
    <div class="text-center">
      <img src="assets/imgs/icones/image-up.svg" alt="" class="mb-2">
      <p>Cole um print aqui</p>
      <button class="btn btn-primary"  type="button" (click)="fileInput.click()">
        <span>Selecionar do computador <img class="ms-2" src="assets/imgs/icones/upload-white.svg" alt="" ></span>
      </button>
      <input (change)="onPaste($event)" hidden #fileInput type="file" id="file" accept="image/*">
    </div>
  </div>
  <div *ngIf="image" class="img-central">
    <img [src]='image' class="img-paste text-center">
  </div>
</div>
