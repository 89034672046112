import { Component } from '@angular/core';
import {NovoParceiroService} from "../../../services/novo-parceiro.service";

@Component({
  selector: 'app-formulario-dados-concluidos',
  templateUrl: './formulario-dados-concluidos.component.html',
  styleUrls: ['./formulario-dados-concluidos.component.scss']
})
export class FormularioDadosConcluidosComponent {

  constructor(protected novoParceiroService: NovoParceiroService) { }

}
