<div class="d-flex justify-content-center">
  <div style="max-width: 480px">
    <div class="mt-3 mb-3">
      <div class="progress">
        <div class="progress-bar" role="progressbar" [style.width]="totalConcluido" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
      <span class="float-end">{{ stepperFormulario + 1 }}/3</span>
    </div>
    <h4 class="desktop_title_h4" [innerHTML]="tituloCadastro"></h4>
    <p class="desktop_body_P4 desktop_body_P4--color-segundario" [innerHTML]="descricaoCadastro"></p>
    <div>
      <div *ngIf="stepperFormulario === 0">
        <app-formulario-pre-cadastro (pularStep)="stepper($event)"></app-formulario-pre-cadastro>
      </div>
      <div *ngIf="stepperFormulario === 1">
        <app-formulario-dados-complementares (pularStep)="stepper($event)"></app-formulario-dados-complementares>
      </div>
      <div *ngIf="stepperFormulario === 2">
        <app-formulario-dados-concluidos></app-formulario-dados-concluidos>
      </div>
    </div>
  </div>
</div>
