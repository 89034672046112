import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AbstractControl, ControlValueAccessor, ValidationErrors, Validator} from "@angular/forms";

@Component({
  selector: 'app-input-radio-personalizado',
  templateUrl: './input-radio-personalizado.component.html',
  styleUrls: ['./input-radio-personalizado.component.scss']
})
export class InputRadioPersonalizadoComponent implements ControlValueAccessor, Validator  {
  @Input() id = 'inputId'
  @Input() visivel = true;
  @Input() placeholder = 'Definir placeholder';
  @Input() invalid = false;
  @Input() label = 'Definir Label';
  @Input() tipoNumber = true;
  @Input() msgAlerta = 'Campo obrigatório';
  @Output() funcaoExterna = new EventEmitter();

  @Input() inputUm = {
    label: 'Documentos anexados',
    value: 1,
    id: 'inputId'
  }
  @Input() inputDois = {
    label: 'Prints',
    value: 2,
    id: 'inputId'
  }


  @Input() value: any;
  disabled = false;
  onChange: any = (value: any) => {};
  onTouched: any = () => {};

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (!control.value || control.value === '') {
      return { required: true };
    }
    return null;
  }

  selecyType(ev): void {
    this.onChange(ev);
    this.funcaoExterna.emit(ev)
  }

  protected readonly parseInt = parseInt;
}
