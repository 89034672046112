import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import {IFormulario} from "../models/contrato.models";
import {
  IBibliotecaFormularioCarregado,
  IBibliotecaFormularioEnvio,
  IBibliotecaFormularioService
} from "../services/contrato.services";
import {TipoFormulario} from "../enums/enumGenerico.enum";
import {BibliotecaFormularioString} from "../models/formulario.models";
import {AlertaService} from "../services/alerta.service";

/**
 * Classe abstrata do Componente de Formulário
 * @typeparam TModel Classe de modelo das linhas da tabela que implementa a interface [[IModel]]
 */
@Injectable()
export abstract class BibliotecaFormularioComponent<TModel extends IFormulario> implements OnInit, OnDestroy, IBibliotecaFormularioCarregado<TModel>, IBibliotecaFormularioEnvio<TModel> {
  model: TModel;
  form: FormGroup;
  submitted = false; // TODO PENSAR NOME
  carregando = false;
  navegar: boolean;
  tipo: TipoFormulario;
  titulos: BibliotecaFormularioString;
  mensagens: BibliotecaFormularioString;
  retornarLinks: BibliotecaFormularioString;
  carregarManual: boolean;
  validarFormulario = true;
  clickEnviar = false;
  erroSalvarBoolean = false;

  /**
   * Construtor da classe de componente de formulário
   * @param router Router padrão do angular para navegação entre páginas
   * @param route Rota ativa do angular para leitura de parâmetros
   * @param alertaService Serviço de alerta
   * @param service Serviço de recuperação e gravação do modelo que implementa a interface de serviço para formulário
   * @param titulo Objeto para definição do título de formulário para Criação e Edição
   * @param mensagem Objeto para definição da mensagem de sucesso do formulário para Criação e Edição
   * @param retornarLink Objeto para definição do redirecionamento do formulário após gravação para Criação e Edição
   */
  protected constructor(protected router: Router,
                        protected route: ActivatedRoute,
                        protected alertaService: AlertaService,
                        protected service: IBibliotecaFormularioService<TModel>,
                        titulo: BibliotecaFormularioString | Array<string>,
                        mensagem: BibliotecaFormularioString | Array<string>,
                        retornarLink?: BibliotecaFormularioString | Array<string>,
                        navegar?: boolean,
                        carregarManual?: boolean) {
    this.titulos = (titulo instanceof Array) ? new BibliotecaFormularioString(titulo) : titulo;
    this.mensagens = (mensagem instanceof Array) ? new BibliotecaFormularioString(mensagem) : mensagem;
    this.retornarLinks = retornarLink != null ? (retornarLink instanceof Array) ? new BibliotecaFormularioString(retornarLink) : retornarLink : new BibliotecaFormularioString(['../', '../../']);
    this.tipo = null;
    this.carregando = true;
    this.navegar = navegar != null ? navegar : true;
    this.carregarManual = carregarManual != null ? carregarManual : false;
  }

  // Interfaces
  ngOnInit() { }

  ngOnDestroy() { }

  antesCarregar(model: TModel) { }
  depoisCarregar(model: TModel) { }
  antesEnviar() { }
  depoisEnviar(model: TModel) { }

  erroSalvar(e) { }

  // Getters
  get f(): FormGroup { return this.form; }
  get fc(): { [key: string]: AbstractControl; } { return this.form.controls; }
  get titulo(): string { return this.titulos.get(this.tipo); }
  get mensagem(): string { return this.mensagens.get(this.tipo); }
  get retornarLink(): string { return this.retornarLinks.get(this.tipo); }
  set retornarLink(value) { this.retornarLinks = new BibliotecaFormularioString(['../' + value, '../../' + value]); }

  // Methods

  start() {
    this.route.params.subscribe(params => this.load(params));
  }

  load(params: object) {
    if (params.hasOwnProperty('id')) {
      // tslint:disable-next-line: no-string-literal
      this.service.get(params['id']).subscribe(x => {
        this.loaded(x, TipoFormulario.Editar);
      });
    }
    else {
      this.loaded(this.service.criar(), TipoFormulario.Criar);
    }
  }

  loaded(model: TModel, tipo: TipoFormulario) {
    this.antesCarregar(model);
    this.model = model;
    this.form = this.model.asForm;
    this.tipo = tipo;
    this.carregando = false;
    this.depoisCarregar(model);
  }

  get criarFormulario(): boolean {
    return this.tipo === TipoFormulario.Criar;
  }


  removerId = true;
  enviar(): void {
    this.antesEnviar();
    if (this.form.invalid && this.validarFormulario) { return; }
    if (this.validarFormulario === false) { return; }
    this.submitted = true;
    this.carregando = true;
    this.erroSalvarBoolean = false;
    if (this.tipo === TipoFormulario.Criar) {
      if(this.removerId) {
        delete this.form.value.id;
      }
      this.service.salvar(this.form.value)
        .pipe(first())
        .subscribe(
          d => {
            this.depoisEnviar(d);
            if(this.mensagem) {
              this.alertaService.sucesso(this.mensagem);
            }
          },
          e => {
            this.carregando = false;
            this.erroSalvarBoolean = true;
            this.erroSalvar(e);
            if (e) { this.alertaService.erro(e); }
            else { this.alertaService.erro('Erro ao cadastrar!'); }
          },
          () => {
            if (this.navegar) {
              this.router.navigate([this.retornarLink], { relativeTo: this.route });
            } else if (!this.carregarManual) {
              this.carregando = true;
            }
          }
        );
    } else {
      this.alertaService.dialog('Confirmação de Alteração', 'Deseja continuar?').subscribe(result => {

        if (result) {
          this.service.atualizar(this.form.value)
            .pipe(first())
            .subscribe(
              d => {
                this.depoisEnviar(d);
                this.alertaService.sucesso(this.mensagem);
              },
              e => {
                this.carregando = false;
                this.erroSalvar(e);
                if (e?.errorList?.length && e?.errorList[0]?.message) { this.alertaService.erro(e?.errorList[0]?.message); }
                else { this.alertaService.erro('Erro ao atualizar!'); }

              },
              () => {

                if (this.navegar) {
                  this.start();
                  this.router.navigate([this.retornarLink], { relativeTo: this.route });
                } else if (!this.carregarManual) {
                  this.carregando = true;
                }
              }
            );
        } else {
          this.alertaService.mensagem('Alteração Cancelada.');
          this.carregando = false;
        }
      });
    }
  }

  removerValidator(campo: string): void {
    this.form.get(campo).clearValidators();
    this.form.get(campo).updateValueAndValidity();
  }
}
