import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

//Locales "pt-br"
import { registerLocaleData } from '@angular/common';
import localePT from '@angular/common/locales/pt';
registerLocaleData(localePT);

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfirmarDialogComponent } from './bibliotecaPjus/componentes/confirmar-dialog/confirmar-dialog.component';
import {MaterialModule} from "./shared/material.module";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {HttpService} from "./core/services/http.service";
import {NgxSpinnerModule} from "ngx-spinner";
import {MAT_DATE_LOCALE} from "@angular/material/core";
import {MatBottomSheetRef} from "@angular/material/bottom-sheet";
import {ErrorInterceptor} from "./core/auth/interceptors/error.interceptor";
import {AuthTokenInterceptor} from "./core/auth/interceptors/auth-token.interceptor";
import { PoModule } from '@po-ui/ng-components';
import {ComponentesModule} from "./shared/componentes/componentes.module";
import { AceitarPropostaComponent } from './pages/listagem-proposta/componentes/aceitar-proposta/aceitar-proposta.component';
import {NgxMaskDirective, NgxMaskPipe, provideNgxMask} from "ngx-mask";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgxCurrencyModule} from "ngx-currency";


@NgModule({
  declarations: [
    AppComponent,
    ConfirmarDialogComponent,
    AceitarPropostaComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    NgxSpinnerModule,
    PoModule,
    ComponentesModule,
    NgxMaskPipe,
    FormsModule,
    NgxCurrencyModule,
    ReactiveFormsModule,
    NgxMaskDirective,
  ],
  providers: [
    HttpService,
    {provide: MAT_DATE_LOCALE, useValue: 'pt-BR'},
    { provide: LOCALE_ID, useValue: 'pt-br' },
    { provide: MatBottomSheetRef, useValue: {} },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthTokenInterceptor, multi: true },
    provideNgxMask(),
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
