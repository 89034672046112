import {Component, EventEmitter, HostListener, Input, Output} from '@angular/core';

@Component({
  selector: 'app-input-upload-image',
  templateUrl: './input-upload-image.component.html',
  styleUrls: ['./input-upload-image.component.scss']
})
export class InputUploadImageComponent {
  image;
  nomeArquivo;
  @Output() base64 = new EventEmitter();
  @Input() invalid = false;

  constructor() {
    document.addEventListener('paste', this.onPaste.bind(this));
  }

  @HostListener('paste', ['$event'])
  onPaste(event: any) {
    let items;
    if (!event.clipboardData) {
      items = event;
      if (items.target.files && items.target.files[0]) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const r = e.target.result;
          if (typeof e.target.result === 'string') {
            const base64String = e.target.result.split(',').pop();
            const extensao = items.target.files[0].name.split('.').pop().toLowerCase();
            this.base64.emit({base64String, extensao});
            this.nomeArquivo = items.target.files[0].name;
            this.image = e.target.result;
          }
        };
        reader.readAsDataURL(items.target.files[0]);
      }
    } else {
      items = (event.clipboardData || event.originalEvent.clipboardData).items;
      let blob = null;

      for (const item of items) {
        if (item.type.indexOf('image') === 0) {
          blob = item.getAsFile();
        }
      }

      // load image if there is a pasted image
      if (blob !== null) {
        const reader = new FileReader();
        reader.onload = (evt: any) => {
          this.image = evt.target.result;
          const base64String = evt.target.result.split(',').pop();
          this.base64.emit({base64String});
          this.nomeArquivo = 'Arquivo colado';
        };
        reader.readAsDataURL(blob);
      }
    }
  }

  removerImagem(): void {
    this.base64.emit({base64: null, extensao: null});
    this.nomeArquivo = null;
    this.image = null;
  }

}
