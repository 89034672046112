<form [formGroup]="form" (ngSubmit)="enviar()" *ngIf="!carregando" autocomplete="off" class="mt-2">
  <div class="mb-4 mt-4">
    <mat-radio-group aria-label="Select an option" formControlName="tipoPessoa">
      <mat-radio-button [value]="TipoProspeccao.Fisica">
        <span [class.text-danger]="form?.controls?.tipoPessoa?.invalid && !validarFormulario">Sou pessoa física</span>
      </mat-radio-button>
      <mat-radio-button [value]="TipoProspeccao.Juridica">
        <span [class.text-danger]="form?.controls?.tipoPessoa?.invalid && !validarFormulario">Sou pessoa jurídica (PJ)</span>
      </mat-radio-button>
    </mat-radio-group>
    <br>
    <mat-hint *ngIf="form?.controls?.tipoPessoa?.invalid && !validarFormulario" class="text-danger">Campo obrigatório</mat-hint>
  </div>
  <div class="mb-3">
    <app-input-text class="w-100"
                    label="Email para contato*"
                    [placeholder]="''" [id]="'email'"
                    [invalid]="form.controls.email.invalid && !validarFormulario"
                    formControlName="email"
    ></app-input-text>
  </div>
  <div class="mb-3">
    <app-input-text class="w-100"
                    label="Confirme seu email*"
                    [placeholder]="''" [id]="'confirmeEmail'"
                    [invalid]="form.controls.confirmeEmail.invalid && !validarFormulario || form?.errors?.emailMismatch === true && !validarFormulario"
                    [msgAlerta]="form.controls.confirmeEmail.invalid && !validarFormulario ? 'Campo obrigatório' : 'Os emails não conferem'"
                    formControlName="confirmeEmail"
    ></app-input-text>
  </div>
  <div formArrayName="telefonesContato">
    <div class="col-12" *ngFor="let ac of model?.asTelefoneContatoFormArray?.controls; let i=index">
      <div [formGroupName]="i">
        <div class="mb-3">
          <div class="row">
            <div class="col pe-1">
              <app-input-text class="w-100"
                              [label]=" i > 0 ? 'Telefone ' + (i + 1) +'*' : 'Telefone para contato*'"
                              [mask]="'(00) 0000-0000||(00) 00000-0000'"
                              [placeholder]="''" [id]="'telefone-' + i"
                              [invalid]="campoInvalido(ac, 'telefone') && !validarFormulario"
                              [msgAlerta]="campoInvalidoTelefone(ac, 'telefone')"
                              formControlName="telefone"
              ></app-input-text>
            </div>
            <div *ngIf="i > 0" class="col-auto align-content-end ps-1">
              <button class="btn" type="button" id="deletarDocumento" (click)="removerTelefone(i)"
                      [id]="'remover-telefone-'+i"
                      [class.mb-form-invalid]="campoInvalido(ac, 'telefone') && !validarFormulario"
              >
                <img src="assets/imgs/icones/trash.svg" alt="">
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mb-3 ms-1">
    <button class="btn btn-dashed mb-3 d-flex justify-content-center" id="adicionar-telefone" type="button" (click)="adicionarTelefone()">
      Adicionar telefone
      <mat-icon>add</mat-icon>
    </button>
  </div>
  <div>
    <div class="form-check mb-4">
      <input class="form-check-input" type="checkbox" id="aceite" formControlName="aceite" (click)="toggleAceite()">
      <label class="form-check-label" for="aceite"
             [class.text-danger]="form?.controls?.aceite?.invalid && !validarFormulario">
        Ao preencher o formulário você concorda com nossas
        <a href="https://pjus.com.br/politica-de-privacidade/" target="_blank">Políticas de Privacidade</a>
      </label>
    </div>
    <button type="submit" class="btn btn-primary btn-block w-100 mb-3" id="enviar-pre-cadastro">
      Continuar
    </button>
  </div>
</form>
