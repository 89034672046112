<div class="form-group">
  <label for="file">{{label}}</label>
  <div [ngClass]="!nomeArquivo && invalid ? 'd-block' : 'd-flex'">
    <button class="btn btn-dashed {{cssBotao}}" type="button" (click)="fileInput.click()">
      <div class="d-flex align-items-center">
        <div>{{textoBotao}}</div>
        <img class="ms-2" src="https://framework.pjus.com.br/assets/icons/upload.svg" alt="">
      </div>
    </button>
    <input hidden (change)="uploadArquivo($event)" #fileInput type="file" id="file" accept="application/pdf"
      [ngClass]="{'is-invalid': !nomeArquivo && invalid}">
    <div class="d-flex align-items-center">
      <span class="ms-2 txt-nome-arquivo-upload color-vermelho-pjus" *ngIf="nomeArquivo">
        {{nomeArquivo}}
      </span>
      <img *ngIf="nomeArquivo" src="assets/imgs/icones/check-verde.svg" alt="">
    </div>
    <div class="invalid-feedback">
      Campo obrigatório
    </div>
  </div>
</div>
