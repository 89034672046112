/**
 * Classe de Helper para Enumeradores
 * @typeparam T Definição de enumerador do Helper
 */
export class GenericoTipoEnum<T> {
  protected static Enums: Map<any, any>;

  protected constructor(public key: T, public value: string) {
  }

  public static getTypes(): Array<any> {
    if (this.Enums == null) {
      this.update();
    }

    return Array.from(this.Enums.values());
  }

  public static getMap(): Map<any, any> {
    if (this.Enums == null) {
      this.update();
    }

    return new Map<any, any>(this.Enums);
  }

  public static getName(type: any): string {
    if (this.Enums == null) {
      this.update();
    }
    // tslint:disable-next-line: prefer-const
    let item = this.Enums.get(type);
    return (item != null) ? item.value : '';
  }

  public static getType(type: any): number {
    if (this.Enums == null) {
      this.update();
    }
    return this.Enums.get(type).key;
  }

  private static update() {
    // tslint:disable-next-line: prefer-const
    let list = Object.values(this).filter((v) => v instanceof GenericoTipoEnum);
    this.Enums = new Map<any, any>(list.map(v => [v.key, v] as [any, any]));
  }
}

/**
 * Enumerador de tipo de formulário (Criação ou Edição)
 */
export enum TipoFormulario {
  Criar = 0,
  Editar
}

/**
 * Enumerador de tipo de ordenação (Crescente ou Decrescente)
 */
export enum TipoOrdenacao {
  Crecente = 'ASC',
  Decrecente = 'DESC'
}
