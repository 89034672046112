import {GenericoTipoEnum} from "../../bibliotecaPjus/enums/enumGenerico.enum";

export enum TipoProspeccao {
  Fisica = 1,
  Juridica = 2
}

export class TipoProspeccaoEnum extends GenericoTipoEnum<TipoProspeccao> {
  static readonly Fisica = new TipoProspeccaoEnum(TipoProspeccao.Fisica, 'Física');
  static readonly Juridica = new TipoProspeccaoEnum(TipoProspeccao.Juridica, 'Jurídica');
}
