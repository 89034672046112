import {Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from "@angular/forms";

@Component({
  selector: 'app-input-multi-select',
  templateUrl: './input-multi-select.component.html',
  styleUrls: ['./input-multi-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputMultiSelectComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputMultiSelectComponent),
      multi: true
    }
  ]
})

export class InputMultiSelectComponent {
  @Input() id = 'inputId'
  @Input() tipoSelectNumber = true;
  @Input() placeholder = 'Definir placeholder'
  @Input() invalid = false;
  @Input() label = 'Definir Label';
  @Input() msgAlerta = 'Campo obrigatório';
  @Input() campoPreenchidoBooleanValidado = false;
  @Input() listOptions = [];
  @Output() funcaoExterna = new EventEmitter();
  @Output() inputValidado = new EventEmitter();
  @Input() set campoPreenchido(preenchido: boolean) {
    this.campoPreenchidoBoolean = preenchido;
  }
  campoPreenchidoBoolean = false;
  value: number[];
  disabled = false;
  onChange: any = (value: any) => { };
  onTouched: any = () => {};
  onFocus(): void {
    this.campoPreenchidoBoolean = false;
  }
  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (!control?.value || control?.value === '') {
      return { required: true };
    }
    return null;
  }

  atividadeRobo(): void {
    console.log('robô clicado:', this.listOptions)
    this.inputValidado.emit();
    this.campoPreenchidoBoolean = false;
  }
}
