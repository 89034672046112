import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './navbar/navbar.component';
import {MenuUserAvatarComponent} from "./menu-user-avatar/menu-user-avatar.component";
import {MatMenuModule} from "@angular/material/menu";
import { ChipComponent } from './chip/chip.component';
import {MaterialModule} from "../material.module";
import { FooterComponent } from './footer/footer.component';
import { SemPropostaComponent } from './sem-proposta/sem-proposta.component';
import { ChipStatusNegociacaoComponent } from './chip-status-negociacao/chip-status-negociacao.component';
import { BtnCopyComponent } from './btn-copy/btn-copy.component';
import { AlertaMsgComponent } from './alerta/alerta-msg/alerta-msg.component';
import { BadgeStatusComponent } from './badge-status/badge-status.component';
import { DetalhesPropostaComponent } from './proposta/detalhes-proposta/detalhes-proposta.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DetalhesNegociacaoComponent } from './proposta/detalhes-negociacao/detalhes-negociacao.component';
import { OffcanvasComponent } from './offcanvas/offcanvas.component';
import { FormularioNegociarPropostaComponent } from './proposta/acoes-proposta/formulario-negociar-proposta/formulario-negociar-proposta.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxCurrencyModule } from 'ngx-currency';
import { customCurrencyMaskConfig } from 'src/app/aplicacao/ngx-mask/currency-mask';
import { FormularioAceitarPropostaComponent } from './proposta/acoes-proposta/formulario-aceitar-proposta/formulario-aceitar-proposta.component';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { FormularioCancelarPropostaComponent } from './proposta/acoes-proposta/formulario-cancelar-proposta/formulario-cancelar-proposta.component';
import { FormularioSolicitarNovoCalculoComponent } from './proposta/acoes-proposta/formulario-solicitar-novo-calculo/formulario-solicitar-novo-calculo.component';
import { AlertaDeFeedbackComponent } from './alerta-de-feedback/alerta-de-feedback.component';
import {InputMultiSelectComponent} from "./inputs/input-multi-select/input-multi-select.component";
import {InputTextComponent} from "./inputs/input-text/input-text.component";
import {InputCurrencyComponent} from "./inputs/input-currency/input-currency.component";
import {InputPorcentagemComponent} from "./inputs/input-porcentagem/input-porcentagem.component";
import {InputPorcentagem100Component} from "./inputs/input-porcentagem-100/input-porcentagem-100.component";
import {InputNumerosInteirosComponent} from "./inputs/input-numeros-inteiros/input-numeros-inteiros.component";
import {InputTypeComponent} from "./inputs/input-type/input-type.component";
import {InputTextAreaComponent} from "./inputs/input-text-area/input-text-area.component";
import {DecimalFormatDirective} from "../../directives/decimal-format.directive";
import {InputUploadImageComponent} from "./inputs/input-upload-image/input-upload-image.component";
import {InputUploadFileSimpleComponent} from "./inputs/input-upload-file-simple/input-upload-file-simple.component";
import {InputRadioPersonalizadoComponent} from "./inputs/input-radio-personalizado/input-radio-personalizado.component";
import {InputRadioBooleanComponent} from "./inputs/input-radio-boolean/input-radio-boolean.component";
import {InputDateComponent} from "./inputs/input-date/input-date.component";
import {InputRadioComponent} from "./inputs/input-radio/input-radio.component";
import {InputSelectComponent} from "./inputs/input-select/input-select.component";
import {InputMaskComponent} from "./inputs/input-mask/input-mask.component";
import {DireirosReservadosComponent} from "./direiros-reservados/direiros-reservados.component";
import {FooterAuthComponent} from "./footer-auth/footer-auth.component";
import {TituloLogoPjusComponent} from "./titulo-logo-pjus/titulo-logo-pjus.component";
import {RouterLink} from "@angular/router";
import {InputSenhaComponent} from "./inputs/input-senha/input-senha.component";
const inputs = [
  InputTextComponent,
  InputCurrencyComponent,
  InputPorcentagemComponent,
  InputPorcentagem100Component,
  InputNumerosInteirosComponent,
  InputTypeComponent,
  InputTextAreaComponent,
  DecimalFormatDirective,
  InputMaskComponent,
  InputRadioBooleanComponent,
  InputSelectComponent,
  InputRadioComponent,
  InputDateComponent,
  InputMultiSelectComponent,
  InputRadioBooleanComponent,
  InputRadioPersonalizadoComponent,
  InputUploadFileSimpleComponent,
  InputUploadImageComponent,
  InputPorcentagem100Component,
  InputSenhaComponent
]

@NgModule({
    declarations: [
      inputs,
      NavbarComponent,
      MenuUserAvatarComponent,
      ChipComponent,
      FooterComponent,
      SemPropostaComponent,
      ChipStatusNegociacaoComponent,
      BtnCopyComponent,
      AlertaMsgComponent,
      BadgeStatusComponent,
      DetalhesPropostaComponent,
      SpinnerComponent,
      DetalhesNegociacaoComponent,
      OffcanvasComponent,
      FormularioNegociarPropostaComponent,
      FormularioAceitarPropostaComponent,
      FormularioCancelarPropostaComponent,
      FormularioSolicitarNovoCalculoComponent,
      AlertaDeFeedbackComponent,
      DireirosReservadosComponent,
      FooterAuthComponent,
      TituloLogoPjusComponent
    ],
  exports: [
    inputs,
    NavbarComponent,
    MenuUserAvatarComponent,
    ChipComponent,
    FooterComponent,
    SemPropostaComponent,
    ChipStatusNegociacaoComponent,
    BtnCopyComponent,
    AlertaMsgComponent,
    SpinnerComponent,
    BadgeStatusComponent,
    DetalhesPropostaComponent,
    DetalhesNegociacaoComponent,
    OffcanvasComponent,
    FormularioNegociarPropostaComponent,
    FormularioAceitarPropostaComponent,
    FormularioCancelarPropostaComponent,
    FormularioSolicitarNovoCalculoComponent,
    AlertaDeFeedbackComponent,
    DireirosReservadosComponent,
    FooterAuthComponent,
    TituloLogoPjusComponent
  ],
  imports: [
    CommonModule,
    MatMenuModule,
    MaterialModule,
    NgxSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    NgxMaskDirective,
    NgxMaskPipe,
    RouterLink,
  ]
})
export class ComponentesModule { }
