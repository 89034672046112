import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appDecimalFormat]'
})
export class DecimalFormatDirective {

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInputChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    let inputValue = inputElement.value;

    // Remove caracteres não numéricos, exceto ponto e vírgula
    inputValue = inputValue.replace(/[^0-9.,]/g, '');

    // Substitui múltiplas vírgulas por uma única vírgula
    inputValue = inputValue.replace(/,{2,}/g, ',');

    // Remove zeros à esquerda
    inputValue = inputValue.replace(/^0+/, '');

    // Limita o número de casas decimais a 2
    const parts = inputValue.split(',');
    if (parts[0] && parts[0].length > 2) {
      parts[0] = parts[0].slice(0, 2) + ',';
      inputValue = parts.join(',');
    }

    if (parts[1] && parts[1].length > 2) {
      parts[1] = parts[1].slice(0, 2);
    }
    inputElement.value = inputValue;
  }
}
