<div class="mt-3 mb-3">
  <app-input-text class="w-100"
                  [label]="'Email para contato'"
                  [placeholder]="''" [id]="'emailContato'"
                  [readonly]="true"
                  [value]="novoParceiroService.model.email"
  ></app-input-text>
</div>
<p class="color-sucesso texto-2">
  Agradecemos pelo interesse em estabelecer uma parceria com
  a PJUS. Assim que concluirmos a análise, entraremos em
  contato.
</p>
