import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";
import {FiltroPaginacao, ListaPaginada} from "../bibliotecaPjus/models/comum.models";
import {IBibliotecaFormularioService, IBibliotecaTabelaService} from "../bibliotecaPjus/services/contrato.services";
import {BaseProviderService} from "./base-provider.service";
import {FiltroPropostaModel} from "../aplicacao/models/filtro-proposta.model";
import {NovoParceiro} from "../aplicacao/models/novo-parceiro.model";

@Injectable({
  providedIn: 'root'
})
export class NovoParceiroService extends BaseProviderService implements IBibliotecaTabelaService<NovoParceiro, any, any>, IBibliotecaFormularioService<NovoParceiro> {
  protected module = 'Prospeccao';
  model: NovoParceiro;
  constructor(protected http: HttpClient) {
    super(http);
  }

  criar(): NovoParceiro {
    return new NovoParceiro();
  }

  get(id: string): Observable<NovoParceiro> {
    return this.http.get<NovoParceiro>(this.getUrl(this.v1, this.module, [id]))
      .pipe(map(x => new NovoParceiro(x)));
  }

  list(pagina: number, qtd: number, status, objFiltro, subStatus = null): Observable<any> {
    const filtro = new FiltroPropostaModel(objFiltro);
    return this.http.get(this.getUrl(this.v1, this.module, ['Recuperar', 'Parceiro', 'Pagina', pagina, 'Quantidade', qtd + '?StatusProposta='+ status +
    (filtro.valorMin ? '&ValorMinimo=' + filtro.valorMin : '') +
    (filtro.valorMax ? '&ValorMaximo=' + filtro.valorMax : '') +
    (filtro.periodo ? '&DataInicio=' + filtro.periodo.start : '') +
    (filtro.periodo ? '&DataFim=' + filtro.periodo.end : '') +
    (filtro.documento ? '&DocumentoCedente=' + filtro.documento : '') +
    (subStatus ? '&' + subStatus : '')
    ])).pipe(map(d => new ListaPaginada<NovoParceiroService>(NovoParceiroService, d)));
  }

  filter(filter: FiltroPaginacao<any, any>): Observable<ListaPaginada<NovoParceiro>> {
    return this.http.post<ListaPaginada<NovoParceiro>>(this.getUrl(this.v1, this.module, ['filter']), filter)
      .pipe(map(d => new ListaPaginada<NovoParceiro>(NovoParceiro, d)));
  }

  atualizar(model: NovoParceiro): Observable<NovoParceiro> {
    return this.http.patch<NovoParceiroService>(this.getUrl(this.v1, this.module, [model.id]), model).pipe(map(x => new NovoParceiro(x)));
  }

  salvar(model: NovoParceiro): Observable<NovoParceiro> {
    return this.http.post<NovoParceiro>(this.getUrl(this.v1, this.module, ['DadosBasicos', 'Salvar']), model).pipe(map(x => new NovoParceiro(x)));
  }

  aceitarOferta(model): Observable<NovoParceiro> {
    return this.http.post<NovoParceiro>(this.getUrl(this.v1, this.module, ['Aceite', 'Parceiro']), model).pipe(map(x => x));
  }

  deletar(id: number): Observable<NovoParceiro> {
    return this.http.delete<NovoParceiro>(this.getUrl(this.v1, this.module, [id]))
      .pipe(map(x => new NovoParceiro(x)));
  }

  cancelarProposta(model): Observable<NovoParceiro> {
    return this.http.post<NovoParceiro>(this.getUrl(this.v1, this.module, ['Parceiro', 'ReprovarProposta']), model).pipe(map(x => x));
  }

  novoCalculo(model): Observable<NovoParceiro> {
    return this.http.post<NovoParceiro>(this.getUrl(this.v1, this.module, ['NovoCalculo', 'Proposta', model.propostaId]), model).pipe(map(x => x));
  }

  contraProposta(model: any): Observable<any> {
    return this.http.post(this.getUrl(this.v1, this.module, ['Parceiro', 'GerarContraProposta']), model).pipe(map(x => x));
  }

}
