import {GenericoTipoEnum} from "../../bibliotecaPjus/enums/enumGenerico.enum";

export enum StepperPreCadastroParceiro {
  Avancar = 1,
  Voltar = 2
}

export class StepperPreCadastroParceiroEnum extends GenericoTipoEnum<StepperPreCadastroParceiro> {
  static readonly Avancar = new StepperPreCadastroParceiroEnum(StepperPreCadastroParceiro.Avancar, 'Avançar');
  static readonly Voltar = new StepperPreCadastroParceiroEnum(StepperPreCadastroParceiro.Voltar, 'Voltar');
}
