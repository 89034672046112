import {Auditoria} from "../../bibliotecaPjus/models/comum.models";
import {IFormulario, IModel} from "../../bibliotecaPjus/models/contrato.models";
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import {Negociacao} from "./negociacao.model";
import {TipoArquivoPermitido} from "../enum/tipo-arquivo-permitido.enum";
import {TipoProspeccao, TipoProspeccaoEnum} from "../enum/tipoPessoaProspeccao.enum";

export class NovoParceiro extends Auditoria implements IModel, IFormulario {

  id: string;
  tipoPessoa: number;
  email: string;
  confirmeEmail: string;
  aceite: boolean;
  telefonesContato: Array<Telefone> = [];

  form: FormGroup;

  constructor(other?: any) {
    super(other);
    if (other) {
      this.id = other.id;
      this.tipoPessoa = other.tipoPessoa;
      this.email = other.email;
      this.confirmeEmail = other.id ? other.email : other.confirmeEmail;
      this.aceite = other.aceite;
      this.telefonesContato = new Array<Telefone>();
      if(other.telefonesContato != null && other.telefonesContato instanceof Array) {
        other.telefonesContato.forEach((value) => { this.telefonesContato.push(new Telefone(value)); });
      }
    }
  }

  get asForm(): FormGroup {
    if (this.form == null) {
      const telefonesContato = [];
      this.telefonesContato.forEach((value) => telefonesContato.push(value.asForm));
      this.form = new FormGroup({
        id: new FormControl(this.id),
        tipoPessoa: new FormControl(this.tipoPessoa, [Validators.required]),
        email: new FormControl(this.email, [Validators.required, Validators.email, emailValidado()]),
        confirmeEmail: new FormControl(this.confirmeEmail, [Validators.required, Validators.email, emailMatcherValidator]),
        aceite: new FormControl(this.aceite, [Validators.required, Validators.requiredTrue]),
        telefonesContato: new FormArray(telefonesContato)
      }, { validators: emailMatcherValidator });
    }
    return this.form;
  }

  get asTelefoneContatoFormArray(): FormArray {
    const x = this.form.get('telefonesContato') as FormArray;
    const v: AbstractControl = x?.controls[0];
    return this.form.get('telefonesContato') as FormArray;
  }
  updateForm(other): void {
    this.form.patchValue(other);
  }
}

export class Telefone {
  telefone: string;
  form: FormGroup;
  constructor(other?: any) {
    if (other) {
      this.telefone = other.telefone;
    }
  }

  get asForm(): FormGroup {
    if (this.form == null) {
      this.form = new FormGroup({
        telefone: new FormControl(this.telefone, [Validators.required, Validators.minLength(10)])
      });
    }
    return this.form;
  }
}
export const emailMatcherValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const email = control.get('email');
  const confirmeEmail = control.get('confirmeEmail');

  if (!email || !confirmeEmail) {
    return null;
  }

  return email.value === confirmeEmail.value ? null : { emailMismatch: true };
};
export function emailValidado(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,}$/;
    const valid = emailRegex.test(control.value);
    return valid ? null : { customEmail: true };
  };
}
