<label class="form-label">{{label}}</label>
<input
        [type]="tipoInput"
        [(ngModel)]="value"
        [disabled]="disabled"
        (input)="onChange(value)"
        (blur)="onTouched()"
        class="form-control"
        [id]="id"
        [placeholder]="placeholder"
        [ngClass]="{'is-invalid': invalid}"
/>

<div *ngIf="invalid" class="invalid-feedback">
    <span>{{msgAlerta}}</span>
</div>
