import {Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from "@angular/forms";
import {TipoBoolPreenchido} from "../base/enum/TipoBoolPreenchido.enum";

@Component({
  selector: 'app-input-radio',
  templateUrl: './input-radio.component.html',
  styleUrls: ['./input-radio.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputRadioComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputRadioComponent),
      multi: true
    }
  ]
})
export class InputRadioComponent  implements ControlValueAccessor, Validator  {
  @Input() id = 'inputId'
  @Input() placeholder = 'Definir placeholder'
  @Input() invalid = false;
  @Input() label = 'Definir Label';
  @Input() tipoNumber = true;
  @Input() msgAlerta = 'Campo obrigatório';
  @Input() campoPreenchidoBooleanValidado = false;
  @Input() textoDireita = false;
  @Input() disabledSim = false;
  @Input() disabledNao = false;
  @Input() disabledTodos = false;
  @ViewChild('checkSim') checkSim: ElementRef;
  @ViewChild('checkNao') checkNao: ElementRef;
  @Output() funcaoExterna = new EventEmitter();
  @Output() inputValidado = new EventEmitter();
  @Input() set preencherFuncaoExterna(acao) {
    if(acao.acionar) {
      if(this.checkSim && this.checkNao){
        if(acao.valor === TipoBoolPreenchido.Sim) {
          this.checkSim.nativeElement.click();
        } else if (acao === TipoBoolPreenchido.Nao) {
          this.checkNao.nativeElement.click();
        }
      }
    }
  }
  @Input() set campoPreenchido(preenchido: boolean) {
    this.campoPreenchidoBoolean = preenchido;
  }
  campoPreenchidoBoolean = false;
  value: string;
  disabled = false;
  onChange: any = (value: any) => {};
  onTouched: any = () => {};

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (!control.value || control.value === '') {
      return { required: true };
    }
    return null;
  }

  selecyType(ev): void {
    this.campoPreenchidoBoolean = false;
    this.inputValidado.emit();
    this.onChange(ev);
    this.funcaoExterna.emit(ev)
  }

  testeA(value): boolean {
    if(value === 2){
      return true
    }
    return false
  }

  testeB(value): boolean {
    if(value === 3){
      return true
    }
    return false
  }

  removerNumeroString(input: string): string {
    if(input) {
      return input.replace(/^[0-9]+/, '');
    } else {
      return '';
    }
  }

  protected readonly parseInt = parseInt;
}
