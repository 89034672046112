import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {AbstractControl} from "@angular/forms";
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-input-porcentagem',
  templateUrl: './input-porcentagem.component.html',
  styleUrls: ['./input-porcentagem.component.scss'],
})
export class InputPorcentagemComponent implements OnInit {
  @Input() control: AbstractControl<any>;
  @Input() validarFormulario;
  @Input() label;
  @Input() id;
  @Input() readonly = false;
  @Input() campoPreenchidoBooleanValidado = false;
  @Output() inputValidado = new EventEmitter();
  @Input() set campoPreenchido(preenchido: boolean) {
    this.campoPreenchidoBoolean = preenchido;
  }
  campoPreenchidoBoolean = false;
  formattedValue = "";
  onvalueChanges: Subscription = new Subscription;

  ngOnInit() {
    this.control.valueChanges.subscribe(
      res => {
        //Formata a porcentagem depois que há alterações com ".setValue()"
        this.formatarPorcentagem(this.control.value);
      }
    )
  }

  ngOnDestroy() {
		this.onvalueChanges.unsubscribe();
	}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.control && changes.control.currentValue) {
      //Formata a porcentagem a primeira vez que o componente carrega
      this.formatarPorcentagem(changes.control.currentValue.value);
    }
  }

  onFocus(): void {
    this.campoPreenchidoBoolean = false;
    this.inputValidado.emit();
  }

  inputOnChange(ev) {
    let value = ev.target.value;
    if(value){
      this.control.setValue(parseFloat(value.replace(",", ".")));
    }
    else {
      this.control.setValue(null);
    }
    this.formatarPorcentagem(ev.target.value);
  }

  formatarPorcentagem(value) {
    if (value || value == 0) {
      this.formattedValue = value.toString().replace(".", ",");
    }
    else{
      this.formattedValue = "";
    }
  }

  invalidInputAtivo() {
    if(this.control.invalid && !this.validarFormulario){
      return true;
    }
  }

  removerNumeroString(input: string): string {
    if(input) {
      return input.replace(/^[0-9]+/, '');
    } else {
      return '';
    }
  }
}
