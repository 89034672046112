import {Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from "@angular/forms";

@Component({
  selector: 'app-input-currency',
  templateUrl: './input-currency.component.html',
  styleUrls: ['./input-currency.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputCurrencyComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputCurrencyComponent),
      multi: true
    }
  ],
})
export class InputCurrencyComponent implements ControlValueAccessor, Validator  {
  @Input() id = 'inputId'
  @Input() placeholder = 'Definir placeholder'
  @Input() invalid = false;
  @Input() label = 'Definir Label';
  @Input() msgAlerta = 'Campo obrigatório';
  @Input() mask;
  @Input() suffix = '';
  @Input() required = true;
  @Output() funcaoExterna = new EventEmitter();
  @Output() inputValidado = new EventEmitter();
  @Input() campoPreenchidoBooleanValidado = false;
  @Input() set campoPreenchido(preenchido: boolean) {
    this.campoPreenchidoBoolean = preenchido;
  }
  campoPreenchidoBoolean = false;
  value: string;
  disabled = false;
  onChange: any = (value: any) => {};
  onTouched: any = () => {};

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if(this.required) {
      if (!control.value || control.value === '') {
        return { required: true };
      }
    } else {
      return null;
    }
  }

  chamarExterno(value: any): void {
    this.funcaoExterna.emit(value)
  }

  ev(ev): void {
    this.onChange(ev);
    this.funcaoExterna.emit(ev);
  }

  onFocus(): void {
    this.campoPreenchidoBoolean = false;
    this.inputValidado.emit();
  }

  removerNumeroString(input: string): string {
    if(input) {
      return input.replace(/^[0-9]+/, '');
    } else {
      return '';
    }
  }

}
