import {Auditoria} from "../../bibliotecaPjus/models/comum.models";
import {IFormulario, IModel} from "../../bibliotecaPjus/models/contrato.models";
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import {Negociacao} from "./negociacao.model";
import {TipoArquivoPermitido} from "../enum/tipo-arquivo-permitido.enum";
import {TipoProspeccao} from "../enum/tipoPessoaProspeccao.enum";
import {NovoParceiroService} from "../../services/novo-parceiro.service";

export class NovoParceiroDadosCompletaresModel extends Auditoria implements IModel, IFormulario {

  id: string;
  documento: string;
  nome: string;
  nomeContato: string;
  dataNascimento: string;
  documentoRg: string;
  orgaoEmissor: string;
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  cidade: string;
  numero: string;
  uf: string;
  tipoPessoa: number;

  form: FormGroup;

  constructor(other?: any) {
    super(other);
    if (other) {
      this.id = other.id;
      this.documento = other.documento;
      this.nome = other.nome;
      this.nomeContato = other.nomeContato;
      this.dataNascimento = other.dataNascimento;
      this.documentoRg = other.documentoRg;
      this.orgaoEmissor = other.orgaoEmissor;
      this.cep = other.cep;
      this.logradouro = other.logradouro;
      this.complemento = other.complemento;
      this.bairro = other.bairro;
      this.cidade = other.cidade;
      this.numero = other.numero;
      this.uf = other.uf;
    }
  }

  get asForm(): FormGroup {
    if (this.form == null) {
      this.form = new FormGroup({
        id: new FormControl(this.id),
        documento: new FormControl(this.documento, [Validators.required, this.validarDocumento()]),
        nome: new FormControl(this.nome, [Validators.required]),
        nomeContato: new FormControl(this.nomeContato, [Validators.required]),
        dataNascimento: new FormControl(this.dataNascimento, [Validators.required, idadeValidator]),
        documentoRg: new FormControl(this.documentoRg),
        orgaoEmissor: new FormControl(this.orgaoEmissor),
        cep: new FormControl(this.cep, [Validators.required]),
        logradouro: new FormControl(this.logradouro, [Validators.required]),
        complemento: new FormControl(this.complemento),
        bairro: new FormControl(this.bairro, [Validators.required]),
        cidade: new FormControl(this.cidade, [Validators.required]),
        numero: new FormControl(this.numero, [Validators.required]),
        uf: new FormControl(this.uf, [Validators.required]),
      });
    }
    return this.form;
  }

  updateForm(other): void {
    this.form.patchValue(other);
  }

  // Exclusivo para novo parceiro
  validarDocumento() {
    return (control: AbstractControl): Validators => {
      const cpf = control.value;
      if (cpf) {
        let numeros, digitos, soma, i, resutado, digitosIguais;
        digitosIguais = 1;
        if (cpf.length > 11) {
          if(this.isValidCnpj(cpf)) {
            return null;
          } else {
            return {documentoInvalido: true};
          }
        }

        for (i = 0; i < cpf.length - 1; i++) {
          if (cpf.charAt(i) !== cpf.charAt(i + 1)) {
            digitosIguais = 0;
            break;
          }
        }

        if (!digitosIguais) {
          // Verificar o tipo do Usuario
          if(this.tipoPessoa === TipoProspeccao.Fisica) {
            numeros = cpf.substring(0, 9);
            digitos = cpf.substring(9);
            soma = 0;
            for (i = 10; i > 1; i--) {
              soma += numeros.charAt(10 - i) * i;
            }

            resutado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

            if (resutado !== Number(digitos.charAt(0))) {
              return {documentoInvalido: true};
            }
            numeros = cpf.substring(0, 10);
            soma = 0;

            for (i = 11; i > 1; i--) {
              soma += numeros.charAt(11 - i) * i;
            }
            resutado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

            if (resutado !== Number(digitos.charAt(1))) {
              return {documentoInvalido: true};
            }
            return null;
          } else {
            return {documentoInvalido: true};
          }
        } else {
          return {documentoInvalido: true};
        }
      }
      return null;
    };
  }

  isValidCnpj(cnpj: string): boolean {
    cnpj = cnpj.replace(/[^\d]+/g, '');
    if (cnpj.length !== 14) {
      return false;
    }

    if (/^(\d)\1+$/.test(cnpj)) {
      return false;
    }

    let soma = 0;
    let fator = 5;
    for (let i = 0; i < 12; i++) {
      soma += parseInt(cnpj.charAt(i)) * fator;
      fator = fator === 2 ? 9 : fator - 1;
    }

    let restante = soma % 11;
    let digito = restante < 2 ? 0 : 11 - restante;

    if (parseInt(cnpj.charAt(12)) !== digito) {
      return false;
    }

    soma = 0;
    fator = 6;
    for (let i = 0; i < 13; i++) {
      soma += parseInt(cnpj.charAt(i)) * fator;
      fator = fator === 2 ? 9 : fator - 1;
    }

    restante = soma % 11;
    digito = restante < 2 ? 0 : 11 - restante;

    if (parseInt(cnpj.charAt(13)) !== digito) {
      return false;
    }

    return true;
  }
}
export const idadeValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const dataNascimento = new Date(control.value);
  const hoje = new Date();
  let idade = hoje.getFullYear() - dataNascimento.getFullYear();
  const disferencaMes = hoje.getMonth() - dataNascimento.getMonth();

  if (disferencaMes < 0 || (disferencaMes === 0 && hoje.getDate() < dataNascimento.getDate())) {
    idade--;
  }

  return idade >= 18 ? null : { menorIdade: true };
};
